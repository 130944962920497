import Indonesian from './indonesia'
import English from './english'

const getStringByLanguage = (lang) => {
  let strings
  // console.log('language -> ' + lang)
  switch (lang) {
    case 'id':
      strings = Indonesian
      break
    default:
      strings = English
  }

  return new Proxy(strings, {
    get: (target, key) => {
      try {
        if (key in target) {
          return target[key]
        } else {
          if (key.toLowerCase().indexOf('error') === 0) {
            return `UNDOCUMENTED ERROR [${lang}]`
          }
          return `LANG_${lang}.${key}`
        }
      } catch (e) {
        return undefined
      }
    }
  })
}

export default getStringByLanguage

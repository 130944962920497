import HttpRequest, {MapToQueryString} from "./HttpRequest";
import store from "../../store";
import router from '../../router';

const JSONPromise = (initiator, bodyTransform) => {
	if (typeof bodyTransform !== 'function') {
		bodyTransform = (body) => body;
	}
	return async function (body) {
		return new Promise(function (resolve, reject) {
			initiator(bodyTransform(body))
				.then((response) => {
					return response.json();
				})
.then((json) => {					
                    console.log("🚀 ~ file: JSONRequest.js ~ line 16 ~ .then ~ json", json)
					if (json.status === "Authorization Token not found") {
						// const store = useStore();
						store.dispatch('logout')
						router.push({ name: 'Login' })
					} else {
						resolve(json);
					}
				})
				.catch((error) => {
					reject(error)
                    console.log("🚀 ~ file: JSONRequest.js ~ line 25 ~ error", error)
				});
		});
	}
};

const JSONRequest = function (path, method, bodyTransformer) {
	let req = new HttpRequest(path, method);
	req.setHeader('Content-Type', 'application/json');
	req.setHeader('Accept', '*/*');
	// req.setHeader("Access-Control-Allow-Origin", "*");
	req.send = JSONPromise(req.send, bodyTransformer);
	return req;

};

const GetJSON = function (path, GPSPriority) {
	return JSONRequest(path, 'GET', MapToQueryString, GPSPriority);
};

const PostJSON = function (path) {
	return JSONRequest(path, 'POST', JSON.stringify);
};

const PutJSON = function (path) {
	return JSONRequest(path, 'PUT', JSON.stringify);
};

const DeleteJSON = function(path) {
	return JSONRequest(path, 'DELETE')
}

export {GetJSON, PostJSON, PutJSON, DeleteJSON}
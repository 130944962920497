// import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import store from '../store'
const routes = [
  
  {
    path: '/:id_code',
    name: 'start',
    component: () => import('@/views/e-prescription/Start'),
  },
  {
    path: '/validation/:id_code',
    name: 'validation',
    component: () => import('@/views/e-prescription/Validation'),
  },
  {
    path: '/end',
    name: 'end',
    component: () => import('@/views/e-prescription/End.vue')
  }

  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const authenticatedUser = !!store.getters.isLoggedIn
  console.log("🚀 ~ file: index.js ~ line 306 ~ router.beforeEach ~ authenticatedUser", authenticatedUser,store.getters.isLoggedIn,store.getters.getAuth)
  // console.log(authenticatedUser, store.state.auth, !store.getters.isLoggedIn)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (requiresAuth && !authenticatedUser) next('login')
  else next()
})

export default router

// import {post} from "@/api";
// import Common from "@/config/Common";
// import store from './store'
import VueJwtDecode from 'vue-jwt-decode'
let token = localStorage.getItem('token');
let dataLogin = null;
if(token !== null){
  console.log("🚀 ~ file: _nav.js ~ line 6 ~ token", token)
  dataLogin = VueJwtDecode.decode(token);
  console.log("🚀 ~ file: _nav.js ~ line 7 ~ dataLogin", dataLogin)
}else{
  dataLogin = 0;
}


const superAdmin = [{
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavGroup',
    name: 'Master',
    to: '/master',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Mitra',
        to: '/master/partner',
      },
      {
        component: 'CNavItem',
        name: 'User',
        to: '/master/user',
      },
      {
        component: 'CNavItem',
        name: 'Poly',
        to: '/master/poly',
      },
      {
        component: 'CNavItem',
        name: 'Dokter',
        to: '/master/doctor',
      },
      {
        component: 'CNavItem',
        name: 'Medicine',
        to: '/master/medicine',
      },
      {
        component: 'CNavItem',
        name: 'Admin',
        to: '/master/admin',
      },
      {
        component: 'CNavItem',
        name: 'Artikel',
        to: '/master/article',
      },
      {
        component: 'CNavItem',
        name: 'Banner',
        to: '/master/banner',
      },
      {
        component: 'CNavItem',
        name: 'Terms and Conditions',
        to: '/master/tnc',
      },
      {
        component: 'CNavItem',
        name: 'Homecare Service',
        to: '/master/homecare_service',
      },
      // {     
      //   component: 'CNavItem',
      //   name: 'Role',
      //   to: '/master/role',
      // },
    ]
  },
  {
    component: 'CNavGroup',
    name: 'Report',
    to: '/history',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Antar Obat',
        to: '/history/medicine-delivery',
      },
      {
        component: 'CNavItem',
        name: 'Jadwal Online',
        to: '/history/online-schedule',
      },
      {
        component: 'CNavItem',
        name: 'Refund',
        to: '/history/refund',
      },
      {
        component: 'CNavItem',
        name: 'Doctor Online',
        to: '/history/online-doctor',
      },
      {
        component: 'CNavItem',
        name: 'Homecare',
        to: '/history/homecare',
      },
      {
        component: 'CNavItem',
        name: 'Pembayaran',
        to: '/history/payment',
      },
      {
        component: 'CNavItem',
        name: 'User',
        to: '/history/user',
      },
      {
        component: 'CNavItem',
        name: 'Penyakit',
        to: '/history/diagnose',
      },
      {
        component: 'CNavItem',
        name: 'Obat',
        to: '/history/medicine',
      },
    ]
  },
  {
    component: 'CNavGroup',
    name: 'Log',
    to: '/log',
    icon: 'cil-calculator',
    items: [
      {
        component: 'CNavItem',
        name: 'Payment',
        to: '/logs/payment',
      },
      {
        component: 'CNavItem',
        name: 'Delivery',
        to: '/logs/delivery',
      },
      {
        component: 'CNavItem',
        name: 'Stock Obat',
        to: '/logs/stock-medicine',
      },
      {
        component: 'CNavItem',
        name: 'Harga Obat',
        to: '/logs/price-medicine',
      },
    ]
  },
  {
    component: 'CNavGroup',
    name: 'Wellings',
    to: '/wellings',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: 'Total Consultation',
        to: '/wellings/total-consultation',
      },
    ]
  }
]
const userAdmin = [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Poliklinik',
    icon: 'cil-star',
    to: '/master/polyclinic',
  },
  {
    component: 'CNavItem',
    name: 'Medicine',
    icon: 'cil-calculator',
    to: '/master/medicine',
  },
  {
    component: 'CNavGroup',
    name: 'History',
    to: '/history',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Antar Obat',
        to: '/history/medicine-delivery',
      },
      {
        component: 'CNavItem',
        name: 'Jadwal Online',
        to: '/history/online-schedule',
      },
      {
        component: 'CNavItem',
        name: 'Doctor Online',
        to: '/history/online-doctor',
      },
      {
        component: 'CNavItem',
        name: 'Homecare',
        to: '/history/homecare',
      },
      {
        component: 'CNavItem',
        name: 'Pembayaran',
        to: '/history/payment',
      },
    ]
  },
]
const faskes = dataLogin.id_medical_facility > 0 ? userAdmin : superAdmin

export default faskes

import {  GetJSON, PostJSON, PutJSON, DeleteJSON } from "../helper/Network/";
import HttpRequest from "../helper/Network/HttpRequest";
import FormDataReq, {postFile} from '../helper/Network/FormDataReq'
import {resolveGET, resolvePOST, resolvePUT, resolveDELETE} from './Common'
global.SDHttpRequest                = HttpRequest;
global.SDHttpGetJSON                = GetJSON;
global.SDHttpPostJSON               = PostJSON;
global.SDHttpPutJSON                = PutJSON;
global.SDHttpDeleteJSON             = DeleteJSON;
global.SDResolvePOST                = resolvePOST;
global.SDResolveGET                 = resolveGET;
global.SDResolvePUT                 = resolvePUT;

global.SDResolveDELETE              = resolveDELETE;
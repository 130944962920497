<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CModal
        :style="{ borderRadius: 15 + 'px' }"
        :visible="logoutAlert"
        @close="
          () => {
            logoutAlert = false
          }
        "
      >
        <CModalHeader>
          <CModalTitle class="blue">Medical Resume</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div><span>Apakah anda yakin ingin keluar?</span></div>
          <CButton class="me-3 mt-2" :style="successButton" @click="logout">
            <span>Ya</span>
          </CButton>
          <CButton
            class="me-3 mt-2"
            :style="failedButton"
            @click="
              () => {
                logoutAlert = false
              }
            "
          >
            <span>Tidak</span>
          </CButton>
        </CModalBody>
      </CModal>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav>
        <CNavItem>
          <CDropdown color="link" togglerText="Dropdown button">
            <CDropdownToggle component="a" :style="yfTheme.white_button">{{label_lang}}</CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem @click="change_language">{{dropdown_lang}}</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CNavItem>
        <CNavItem>
          <CNavLink>
            <CIcon
              class="mx-2"
              :style="{ color: '#0280C6' }"
              icon="cil-bell"
              size="lg"
            />
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccnt />

        <CNavItem class="ms-2 my-2 me-4">
          <div :style="{ flex: 1 }">
            Hi,
            <span :style="{ color: '#0280C6' }"
              >{{ doctorName }}</span
            >
          </div>
        </CNavItem>
        <CButton
          class="px-4"
          :style="yfTheme.blue_button"
          @click="() => {logoutAlert = true}"
        >
          Logout
        </CButton>
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import bell from '../../public/bell.png'
import { useStore } from 'vuex'
import Common from '../config/Common'
import router from '../router'
import VueJwtDecode from 'vue-jwt-decode'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    const store = useStore()
    return { store }
  },
  data: () => ({
    logo: bell,
    logoutAlert: false,
  }),
  computed: {
    successButton() {
      return {
        backgroundColor: Common.DEFAULT.COLOR.BLUE,
        ...this.commonButton,
      }
    },
    failedButton() {
      return {
        backgroundColor: Common.DEFAULT.COLOR.YELLOW,
        ...this.commonButton,
      }
    },
    commonButton() {
      return { borderRadius: 15 + 'px', color: Common.DEFAULT.COLOR.WHITE }
    },
    doctorName() {
      // console.log(localStorage.getItem('token'));
      const token = localStorage.getItem('token');
      console.log("🚀 ~ file: AppHeader.vue ~ line 123 ~ doctorName ~ token", token)
      const decode = VueJwtDecode.decode(token);
      return decode.first_name + ' ' + decode.last_name
    },
    label_lang() {
      return this.$store.getters.current_lang === 'id' ? this.language.ind_lang : this.language.eng_lang
    },
    dropdown_lang(){
      return this.$store.getters.current_lang !== 'id' ? this.language.ind_lang : this.language.eng_lang
    },
  },
  methods: {
    logout() {
      // this.logoutAlert = true
      this.$store.dispatch('logout')
      localStorage.removeItem('token')
      const check = this.$store.getters.authId
      console.log(check)

      if (check === null) {
        router.push({ name: 'Login' })
      }
    },
    change_language(){
      const lang = this.dropdown_lang === this.language.ind_lang ? 'id' : 'en' ;
      this.$store.dispatch('updateLang', lang);
    }
  },
}
</script>
<style>
.text {
  font-family: Open Sans;
  font-style: normal;
}
</style>


export function toggleSidebar(state) {
  state.sidebarVisible = !state.sidebarVisible
}
export function toggleUnfoldable(state) {
  state.sidebarUnfoldable = !state.sidebarUnfoldable
}
export function updateSidebarVisible(state, payload) {
  state.sidebarVisible = payload.value
}
export function updateAuth(state, id) {
  state.auth = id
}
export function setProfile(state, data) {
  state.profile = data
}
export function changeLanguage(state, data){
  state.activeLanguage = data
}

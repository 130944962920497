const Common = {
  URL: 
  'https://api.dashboard.yukfit.id/v1/',
  // 'https://staging.api.console.yukfit.id/v1/',
  URL_PASIEN: 'https://apipasien.yukfit.id/api/',
  URL_PHOTO: 'https://yukfitstorage001.sgp1.digitaloceanspaces.com/',
  ONESIGNAL_APP_ID: '51ce30f1-6d25-4516-99e8-ff4b239790f2' ,
  API: {  
    //AUTHENTICATION
    LOGIN: 'admin/login',
    REGISTER: 'admin/register',
    INFO: 'admin/info',
    FORGET_PASSWORD: 'admin/upass',
    UPDATE_PROFILE: 'admin/profile',  
    REFRESH_TOKEN: 'admin/token',
    ADMIN_LIST: 'admin/admin-list',
    REGISTER_ADMIN: '/admin/register'
,
    FETCHING_MENU: '',
    FETCHING_PARTNER: 'partners',
    //MITRA
    FETCHING_MEDICAL_FACILITY: 'medical_facilities',
    INSERT_MEDICAL_FACILITY: 'user/medical_facility/insert',
    CHECK_MEDICAL_RECORD: 'user/medical_facility/check_mr',
  },
  DEFAULT: {
    COLOR: {
      BLUE: '#0280C6',
      YELLOW: '#FFB715',
      DARK_BLUE: '#005D90',
      WHITE: '#FFF',
    },
  },
}

export function resolvePOST(path, data) {
	const request = SDHttpPostJSON(path);
	return request.send(data ?? {});
}

/**
 *
 * @param {String} path where to be directed
 * @param {Object} data the data you want to throw
 * @returns {Promise<Response>|void}
 */
export function resolveGET(path, data) {
	const request = SDHttpGetJSON(path);
	return request.send(data ?? {});
}

export function resolvePUT(path, data) {
	const request = SDHttpPutJSON(path);
	return request.send(data ?? {});
}

export function resolveDELETE(path) {
	const request = SDHttpDeleteJSON(path);
	return request.send({});
}

// Object(Common).freeze();

export default Common
